import { render, staticRenderFns } from "./polar-area.vue?vue&type=template&id=d98b0b10&scoped=true"
import script from "./polar-area.vue?vue&type=script&lang=js"
export * from "./polar-area.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d98b0b10",
  null
  
)

export default component.exports